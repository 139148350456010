import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedFeatureComponentsModule } from '@digilize/shared/feature/components/src';
import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { SharedUiMaterialModule } from '@digilize/shared/ui/material/src';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SharedModule } from '../components/shared.module';
import { HaveResumeComponent } from './have-resume/have-resume.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadYourCvComponent } from './have-resume/upload-your-cv/upload-your-cv.component';
import { UploadedCvComponent } from './have-resume/uploaded-cv/uploaded-cv.component';
import { SelectYourJobComponent } from './have-resume/select-your-job/select-your-job.component';
import { AddYourEducationComponent } from './have-resume/add-your-education/add-your-education.component';
import { SoftSkillsComponent } from './have-resume/soft-skills/soft-skills.component';
import { ProfessionalSkillsComponent } from './have-resume/professional-skills/professional-skills.component';
import { NgxGlideModule } from 'ngx-glide';
import { ItSkillsComponent } from './have-resume/it-skills/it-skills.component';
import { LanguagesKnowledgeComponent } from './have-resume/languages-knowledge/languages-knowledge.component';
import { GeneralInformationComponent } from './have-resume/general-information/general-information.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ProfileCheckComponent } from './my-profile/profile-check/profile-check.component';
import { PipesModule } from '../pipes/pipes.module';
import { SharedFeatureModulesAlertModule } from '@digilize/shared/feature/modules/alert/src';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BeWorkHappyUiComponentsModule } from '@digilize/be-work-happy/ui/components';
import { BeWorkHappyUtilsPipesModule } from '@digilize/be-work-happy/utils/pipes';
import { LoggedInGuard } from '@digilize/shared/feature/modules/auth/src/lib/logged-in.guard';
import { LangComponentsModule } from '@digilize/shared/feature/modules/i18n/src';
import { TasksComponent } from './have-resume/tasks/tasks.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./start/start.module').then((m) => m.StartModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'skilldpaspoort',
    component: HaveResumeComponent,
  },
  {
    path: 'profile-check',
    component: MyProfileComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'profile-check',
      },
      {
        path: '',
        component: ProfileCheckComponent,
      },
    ],
    canActivate: [LoggedInGuard],
    data: { customLoginRedirectRoute: '/login' },
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then((m) => m.OffersModule),
  },
  {
    path: '',
    loadChildren: () => import('./offers/offers.module').then((m) => m.OffersModule),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  declarations: [
    HaveResumeComponent,
    UploadYourCvComponent,
    UploadedCvComponent,
    SelectYourJobComponent,
    AddYourEducationComponent,
    SoftSkillsComponent,
    ProfessionalSkillsComponent,
    ItSkillsComponent,
    LanguagesKnowledgeComponent,
    GeneralInformationComponent,
    MyProfileComponent,
    ProfileCheckComponent,
    TasksComponent,
  ],
  imports: [
    CommonModule,
    SharedUiMaterialModule,
    CdkAccordionModule,
    SharedUiComponentsModule,
    RouterModule.forChild(routes),
    InlineSVGModule,
    TranslateModule,
    SharedModule,
    SharedFeatureComponentsModule,
    MatDialogModule,
    NgxGlideModule,
    PipesModule,
    SharedFeatureModulesAlertModule,
    LottieModule.forRoot({ player: () => player }),
    BeWorkHappyUiComponentsModule,
    BeWorkHappyUtilsPipesModule,
    LangComponentsModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  exports: [HaveResumeComponent],
})
export class MainModule {}
