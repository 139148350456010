<div class="container" [ngClass]="{ 'details-opened': vacancyDetailsLoaded }" *ngIf="loadOffers">
  <div *ngIf="logoImg" [style.background-image]="'url(' + logoImg + '?h=55)'" class="logo-img"></div>
  <!-- <button class="log-in-btn" (click)="navigateToLogin()">{{ 'auth.login' | translate }}</button> -->
  <!-- <div *ngIf="companyBaner" class="header" [style.background-image]="'url(' + companyBaner + '?w=1920)'"></div> -->
  <img *ngIf="companyBaner" class="img-header" [src]="companyBaner + '?w=1920'" />
</div>
<div class="container offers-list" [ngClass]="{ 'details-opened': vacancyDetailsLoaded }" *ngIf="loadOffers">
  <div class="page-content" *ngIf="!loadingData">
    <div class="page-title">
      {{ 'global.work_at' | translate }}
      {{ companyName }}
    </div>
    <div class="page-desc" [innerHTML]="companyDesc"></div>
    <div class="vacancy-offers-cont">
      <div *ngIf="offersList.length === 0" class="no-offers">{{ 'global.no_open_vacancies' | translate }}</div>
      <div *ngFor="let offer of offersList" class="vacancy-offer" (click)="redirectToVacancySummary(offer.id)">
        <div class="vacancy-title">{{ offer.job_title }}</div>
      </div>
      <div
        *ngIf="ifOpenApplicationEnabled && openApplicationTitle && openApplicationTxt"
        class="vacancy-offer open-application"
      >
        <div class="open-title">{{ openApplicationTitle }}</div>
        <div class="open-info" [innerHTML]="openApplicationTxt"></div>
        <button class="btn open-application-btn w-lg h-lg" (click)="openApplicationApply()">
          {{ 'global.apply' | translate }}
        </button>
      </div>
      <!-- <button (click)="checkForm()">check form</button> -->
    </div>
  </div>
  <div #endOfList></div>
</div>
<footer *ngIf="loadOffers"></footer>
