import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'bwh-lib-vacancy-view-summary-v2',
  templateUrl: './vacancy-summary-view-v2.component.html',
  styleUrls: ['./vacancy-summary-view-v2.component.scss'],
})
export class VacancySummaryViewV2Component implements OnInit {
  @Input() form: FormGroup;
  @Input() companyBaner;
  @Input() workLevels;
  @Input() hoursRange;
  @Input() shiftsOptions;
  @Input() employmentType;
  @Input() workHome;
  @Input() numberOfCandidates;
  @Input() topMenu = true;
  @Input() showBottomBtn = true;
  @Input() showAdditionalInfo = false;
  @Input() buttonTxt = 'apply_for_the_match';
  @Input() logoImg: string;
  @Output() buttonClicked = new EventEmitter();
  @Output() redirectToLogin = new EventEmitter();

  blockDescriptions;
  jobDescription;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.extractBlockquotes(this.form.get('job_description').value);
  }

  extractBlockquotes(input: string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = input;
    this.blockDescriptions = Array.from(tempDiv.getElementsByTagName('section')).map((bq) => bq.innerHTML);
    Array.from(tempDiv.getElementsByTagName('section')).forEach((bq) => bq.remove());
    this.jobDescription = tempDiv.innerHTML;
  }

  getDate(date: string) {
    return this.datePipe.transform(date, 'dd MMMM yyyy');
  }

  buttonClick() {
    this.buttonClicked.emit();
  }

  navigateToLogin() {
    this.redirectToLogin.emit();
  }

  getCurrencySymbol(currency) {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      default:
        return currency;
    }
  }

  getCurrencyType(type) {
    switch (type) {
      case 0:
        return 'per_hour';
    }
  }

  checkFrom() {
    console.log('form', this.form.value);
  }
}
