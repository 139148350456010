import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'bwh-lib-vacancy-view-summary',
  templateUrl: './vacancy-summary-view.component.html',
  styleUrls: ['./vacancy-summary-view.component.scss'],
})
export class VacancySummaryViewComponent {
  @Input() form: FormGroup;
  @Input() workLevels;
  @Input() hoursRange;
  @Input() shiftsOptions;
  @Input() employmentType;
  @Input() workHome;
  @Input() numberOfCandidates;
  @Input() topMenu = true;
  @Input() showBottomBtn = true;
  @Input() showAdditionalInfo = false;
  @Input() buttonTxt = 'apply_for_the_match';
  @Input() logoImg: string;
  @Output() buttonClicked = new EventEmitter();
  @Output() redirectToLogin = new EventEmitter();

  constructor() {}

  buttonClick() {
    this.buttonClicked.emit();
  }

  navigateToLogin() {
    this.redirectToLogin.emit();
  }

  getCurrencySymbol(currency) {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      default:
        return currency;
    }
  }

  getCurrencyType(type) {
    switch (type) {
      case 0:
        return 'per_hour';
    }
  }

  checkFrom() {
    console.log('form', this.form.value);
  }
}
