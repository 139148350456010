import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bwh-lib-offers-main-page',
  templateUrl: './offers-main-page.component.html',
  styleUrls: ['./offers-main-page.component.scss'],
})
export class OffersMainPageComponent implements OnChanges {
  form: UntypedFormGroup;
  @Input() companyId;
  @Input() vacancyDetailsLoaded;
  @Input() loadOffers;
  @Input() companyBaner;
  @Input() logoImg;
  @Input() loadingData;
  @Input() companyName;
  @Input() companyDesc;
  @Input() offersList;
  @Input() ifOpenApplicationEnabled = false;
  @Input() openApplicationEnabled;
  @Input() openApplicationId = '';
  @Input() openApplicationTitle = '';
  @Input() openApplicationTxt = '';
  @Input() openApplicationBtnBlock = false;
  @Output() redirectTriggered = new EventEmitter();
  @Output() redirectToLogin = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if ('openApplicationEnabled' in changes) {
      if (this.openApplicationEnabled === 'true') {
        this.ifOpenApplicationEnabled = true;
      }
    }
  }

  checkForm() {
    console.log('form', this.form.value);
  }

  openApplicationApply() {
    if (this.openApplicationId !== '' && !this.openApplicationBtnBlock) {
      const url = window.location.origin + '/login?ofl=' + this.openApplicationId;
      window.open(url, '_blank');
    }
  }

  redirectToVacancySummary(vacancyId) {
    this.redirectTriggered.emit(vacancyId);
  }

  navigateToLogin() {
    this.redirectToLogin.emit();
  }
}
